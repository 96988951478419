import React, {Component} from 'react';
import Navbar from "./Navbar.js";
import Jumbotron1 from "./Jumbotron1.js";
import Projects from "./Projects.js";
import "./App.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faQuoteLeft, faQuoteRight, faPlay, faSun, faMoon} from '@fortawesome/free-solid-svg-icons'
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-145661186-1', { standardImplementation: true });
library.add(fab, faCheckSquare, faCoffee, faQuoteLeft, faQuoteRight, faPlay, faSun, faMoon)

ReactGA.initialize('UA-145661186-1', {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: 123
  }
});


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nightmode: false
    };
    // This binding is necessary to make `this` work in the callback
    this.toggleNightMode = this.toggleNightMode.bind(this);
    // this.calculateAge = this.calculateAge.bind(this);

  }

  componentDidUpdate() {
    document.body.classList.toggle('nightmode', this.state.nightmode);
  }

  toggleNightMode() {
    this.setState(state => ({
      nightmode: !this.state.nightmode
    }));
  }
  render() {
    return (<div>
      <Navbar toggleNightMode={this.toggleNightMode} nightmode={this.state.nightmode}/>
      <Jumbotron1/>
      <Projects/>
      <CookieConsent 
      buttonText="Sure!"
      style={{ background: "#CC5490" }}
      buttonStyle={{ color: "white", background: "#FF0266" }}
      onclick="ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);"
      >
    We love <span role="img" aria-label="">🍪</span>cookies - that's why we track them in this page. If you keep surfing <span role="img" aria-label="">🌊</span> with us, it means that you allow us to do so.
</CookieConsent>
    </div>)
  }
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Contactme from "./Form.js";
import App from "./App.js";

function RouterApp() {
  return (<Router><div>
    <Route exact path="/" component={App}/>
    <Route path="/contact" component={Contactme}/>
  </div>
</Router>
);
}


ReactDOM.render(<RouterApp/>, document.getElementById('root'));

import React, { Component } from "react";
import Mapping1 from "./img/mapping1.JPG";
import Mapping2 from "./img/mapping2.JPG";
import Mapping3 from "./img/mapping3.JPG";
import Abtesting2 from "./img/ABTesting2.png";
import Abtesting1 from "./img/ABTesting1.png";
import Experty from "./img/Experty.jpeg";
import UnregelApp from "./img/unregelapp_portfolio.png";
import WordpressTheme from "./img/Nuria-Coll-Theme.png";
import FramesUnregelApp from "./img/wireframes_UnregelApp.jpeg";
import PersonaUnregelApp from "./img/Handa_UnregelApp.jpg";
import Gif from "./img/Webp.net-gifmaker3.gif";
import Footer from "./Footer.js";
import Music from "./Music.js";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Projects.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

AOS.init();

class Projects extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid projects">
          <div className="col-sm">
            <div className="col-sm-6">
              <p className="empathy">
                “Remember: any problems you have are probably the design's
                fault, not yours.”
              </p>
              <h6 className="citation">
                Don Norman, "The Design of everyday things" (Basic Books, 2013),
                117.
              </h6>
            </div>
            <div
              className="col-sm-6"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <h1 className="title project1">
                Project #1
                <mark className="mark1">UnregelApp</mark>
              </h1>
            </div>
          </div>
          <div className="container-fluid">
            <div
              className="rowprojects"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="row justify-content-end">
                <div className="col-lg-10">
                  <img
                    src={UnregelApp}
                    className="img-fluid ImageProject"
                    alt="UnregelApp"
                  ></img>
                </div>
              </div>
              <div
                className="col-md-5 AboutProject"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <h6
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  About UnregelApp
                </h6>
                <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  UnregelApp is a software project that empowers everyone to
                  memorize the 174 irregular verbs of the German language.
                </p>
                <h6
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  UnregelApp Design Methodology
                </h6>
                <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  The Design Thinking Process. Not problem-focused, but
                  solution-focused. Matches a customer’s needs with
                  well-designed, technologically feasible solutions. Steps:
                  Understand{" "}
                  <span role="img" aria-label="emoji">
                    👍{" "}
                  </span>{" "}
                  &#10095; Observe{" "}
                  <span role="img" aria-label="emoji">
                    👀{" "}
                  </span>{" "}
                  &#10095; Point of View{" "}
                  <span role="img" aria-label="emoji">
                    🙇🏼‍♀️{" "}
                  </span>{" "}
                  &#10095; Ideate{" "}
                  <span role="img" aria-label="emoji">
                    💡
                  </span>{" "}
                  &#10095; Prototype{" "}
                  <span role="img" aria-label="emoji">
                    📝{" "}
                  </span>{" "}
                  &#10095; Test{" "}
                  <span role="img" aria-label="emoji">
                    {" "}
                    🎮{" "}
                  </span>{" "}
                  &#10095; Tell Story{" "}
                  <span role="img" aria-label="emoji">
                    📣{" "}
                  </span>{" "}
                  &#10095; Present{" "}
                  <span role="img" aria-label="emoji">
                    🎪{" "}
                  </span>
                  .
                </p>
                <h6
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  Web Technologies
                </h6>
                <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  ReactJS, CSS, JSX.
                </p>
                <h6
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  GitHub Code
                </h6>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                  href="https://www.github.com/lidiapeleja/unregelreact"
                >
                  lidiapeleja/unregelreact
                </a>
                <h6
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  UnregelApp Website
                </h6>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                  className="anchorInvision"
                  href="https://unregelapp.com"
                >
                  unregelapp.com
                </a>
              </div>
            </div>
            <div className="container-fluid">
              <h2
                className="subtitol"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <mark className="mark1">Understanding the user:</mark>User
                Persona
              </h2>
              <div className="taula container-fluid justify-content-center">
                <div className="row">
                  <div className="col-md left_column">
                    <h5
                      data-aos="fade-up"
                      data-aos-anchor-placement="center-bottom"
                    >
                      Primary Persona: "Handa"
                    </h5>

                    <div className="row">
                      <div className="col image_persona">
                        <img
                          data-aos="fade-up"
                          data-aos-anchor-placement="center-bottom"
                          src={PersonaUnregelApp}
                          alt="Persona UnregelApp"
                          className="img-fluid"
                        ></img>
                      </div>
                    </div>
                  </div>

                  <div className="row right_column">
                    <h5
                      data-aos="fade-up"
                      className="col-lg"
                      data-aos-anchor-placement="center-bottom"
                    >
                      User Stories
                    </h5>
                    <div className="alluserstories">
                      <div
                        className="userstories1"
                        data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom"
                      >
                        <FontAwesomeIcon
                          size="4x"
                          icon="quote-left"
                          className="quote"
                        />
                        I want to be able to learn and test the 174 irregular
                        verbs so that I will make sure I pass my German Goethe
                        Certificate.
                        <hr className="hrsmall"></hr>
                      </div>
                      <div
                        className="userstories2"
                        data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom"
                      >
                        <FontAwesomeIcon
                          size="4x"
                          icon="quote-left"
                          className="quote"
                        />
                        I want to find creative ways to remember the
                        declinations so that I am not only relying on
                        repetition/ memorization processes that can be very
                        easily forgotten.
                        <hr className="hrsmall"></hr>
                      </div>

                      <div
                        className="userstories3"
                        data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom"
                      >
                        <FontAwesomeIcon
                          size="4x"
                          icon="quote-left"
                          className="quote"
                        />
                        I want to share my learning process with my friends so
                        that I am making the process participative and more fun.
                        <hr className="hrsmall"></hr>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-5 AboutProject AboutProject2 rowprojects"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <div className="row">
                  <div className="col">
                    <h6>Problem Statement</h6>
                    <p>
                      Handa needs to find a fun game-like method that helps her
                      to remember, test and practise the list of 174 German
                      irregular verbs.
                    </p>
                  </div>
                </div>
                <h6>Hypotesis Statement:</h6>
                <p>
                  By creating a full game system App that allows Handa to test,
                  practice and remember the 174 irregular verbs, Handa will have
                  the possibility to share, compare her learning results with
                  her friends - while making her learning experience fun,
                  enjoyable and truly meaningful.
                </p>
              </div>
            </div>
            <div className="container-fluid">
              <h2
                className="subtitol"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <mark className="mark1">Low-fidelity Prototypes:</mark>
                first wire-frames & testing
              </h2>
              <img
                src={FramesUnregelApp}
                className="img-fluid"
                alt="Frames UnregelApp"
              ></img>
              <div
                className="col-md-5 AboutProject AboutProject2 rowprojects"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <h6>Usability Testing</h6>
                <p>
                  After designing the first low-fidelity prototypes, based on
                  the needs and goals of our persona, we conducted
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.youtube.com/watch?v=RhgUirqki50"
                  >
                    {" "}
                    five different usability testing{" "}
                  </a>
                  sessions. The testers were 3 woman and 2 men that matched our
                  demographics persona. They were asked to conduct the 4 most
                  fundamental App tasks: Log In, Learn with the App, Test your
                  verbs knowledge, Save results and Exit.
                </p>
                <div
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <p>Schedule: Online, between February 11 - 12 2019.</p>
                  <p>Sessions: 10 minute session for each participant.</p>
                  <p>
                    Equipment: Simulation Phone App design from Prottapp - Tests
                    were not recorded.
                  </p>
                  <p>
                    Metrics:
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.nngroup.com/articles/how-to-rate-the-severity-of-usability-problems/"
                    >
                      {" "}
                      The Jakob Nielsen’s error severity rating{" "}
                    </a>
                    scale.
                  </p>
                  <h6>Usability Testing Conclusions:</h6>
                </div>
                <div
                  className="table-responsive"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                >
                  <table className="table1 table-responsive container-fluid">
                    <thead>
                      <tr>
                        <th scope="col colth">Task</th>
                        <th scope="col colth">Obersvation</th>
                        <th scope="col colth">Severity</th>
                        <th scope="col colth">Recommendation</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Log in</th>
                        <td>
                          Log In and Sign Up are ambiguous when using Facebook
                          or Google Buttons
                        </td>
                        <td>2</td>
                        <td>
                          Unifying “Log in” and “Sign Up” to a Single “Get
                          Started”
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Learn some verbs with the App</th>
                        <td>“Save and Exit” section in Menu is unclear</td>
                        <td>2</td>
                        <td>Making the button “Exit”.</td>
                      </tr>
                      <tr>
                        <th scope="row">Test your knowledge</th>
                        <td>“Home” section is ambiguous.</td>
                        <td>3</td>
                        <td>
                          Remarking (with colours or simple animation) the
                          actual active Level.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Save results and Exit</th>
                        <td>
                          Confusion to know which Verbs have you already passed,
                          and which not
                        </td>
                        <td>2</td>
                        <td>
                          Every time you answer a verb correctly, show “Level”
                          section for a better follow up.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div
              className="row"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="container-fluid col-sm-6"></div>
            </div>
            <div
              className="row"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="container-fluid col-sm-6">
                <h1 className="title project1">
                  Project #2
                  <mark className="mark1">Experty</mark>
                </h1>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div
              className="rowprojects"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <div className="row justify-content-end">
                <div className="col-lg-10">
                  <img
                    src={Experty}
                    className="img-fluid ImageProject"
                    alt="Experty App"
                  ></img>
                </div>
              </div>
            </div>
            <div
              className="col-md-5 AboutProject AboutProject1"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <h6 data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                About Experty App
              </h6>
              <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                The Experty App empowers everyone seeking for professional help
                from any professional field anywhere, anytime.
              </p>
              <h6 data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                Design Methodology
              </h6>
              <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                The Design Thinking Process. Not problem-focused, but
                solution-focused. Matches a customer’s needs with well-designed,
                technologically feasible solutions. Steps: Understand{" "}
                <span role="img" aria-label="emoji">
                  👍{" "}
                </span>{" "}
                &#10095; Observe{" "}
                <span role="img" aria-label="emoji">
                  👀{" "}
                </span>{" "}
                &#10095; Point of View{" "}
                <span role="img" aria-label="emoji">
                  🙇🏼‍♀️{" "}
                </span>{" "}
                &#10095; Ideate{" "}
                <span role="img" aria-label="emoji">
                  💡
                </span>{" "}
                &#10095; Prototype{" "}
                <span role="img" aria-label="emoji">
                  📝{" "}
                </span>{" "}
                &#10095; Test{" "}
                <span role="img" aria-label="emoji">
                  {" "}
                  🎮{" "}
                </span>{" "}
                &#10095; Tell Story{" "}
                <span role="img" aria-label="emoji">
                  📣{" "}
                </span>{" "}
                &#10095; Present{" "}
                <span role="img" aria-label="emoji">
                  🎪{" "}
                </span>
                .
              </p>
              <h6 data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                Invision Prototype
              </h6>
              <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                <a
                  className="anchorInvision"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://projects.invisionapp.com/share/5URJAS0C8FX#/screens"
                >
                  invision.com/ExpertyApp
                </a>
              </p>
            </div>
          </div>

          <div className="container-fluid rowprojects subtitol">
            <h2
              className="subtitol"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <mark className="mark1">Understanding the user:</mark>
              Affinity Mapping
            </h2>
            <div className="row">
              <div className="col-sm">
                <div>
                  <img
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    src={Mapping1}
                    className="img-fluid mapping"
                    alt="Mapping UnregelApp"
                  ></img>
                </div>
              </div>
              <div className="col-sm">
                <div>
                  <img
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    src={Mapping2}
                    className="img-fluid mapping"
                    alt="Mapping UnregelApp"
                  ></img>
                </div>
              </div>
              <div className="col-sm">
                <div>
                  <img
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    src={Mapping3}
                    className="img-fluid mapping"
                    alt="Mapping UnregelApp"
                  ></img>
                </div>
              </div>
            </div>
            <div
              className="col-md-5 AboutProject"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <h6>Affinity Mapping Finding insights</h6>
              <div className="AboutProject4">
                <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  <mark className="markInsights">Goals:</mark>
                  People aged 30 are in a life period where parenthood begins to
                  be very relevant.
                  <mark className="markInsights">Privacy:</mark>
                  Privacy and confidentiality are among the most significant
                  elements from all interviewers
                  <mark className="markInsights">Social Media:</mark>
                  Social Media (mostly Instagram and Facebook) can be an
                  additional source of leads for the App.
                  <mark className="markInsights">
                    Interest for the Service:
                  </mark>
                  People that understand the idea tend to share positive
                  feedback about the service.
                </p>
                <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                  <mark className="markInsights">Professionals:</mark>
                  Trusted professionals are the most important to our potential
                  users. Professionals must be under legal obligations regarding
                  confidentiality. Professionals need to be quickly available.
                  <mark className="markInsights"> Content: </mark> The lack of
                  general reliable content online shows us then potential to
                  allow our professionals to write significant and relevant
                  content, that can help them to improve they visibility in the
                  App, and also in the area where they are really proficient.
                  <mark className="markInsights">Features needed:</mark>
                  The App needs to be light in MB. Articles to read, not only
                  calls or text. Price is a relevant factor for some users,
                  should be properly displayed. Expert App also available from
                  Browser, to bring more flexibility to our users. Professional
                  <mark className="markInsights">Research:</mark>
                  SEO is extremely relevant for the Expert App, as is providing
                  questions that are most of the time trying to be solved by
                  Google. We need to be able to bring confidence to the Apps
                  users, that they are taking the best professional possible, so
                  the research process needs to be extremely clear and
                  problem-solving oriented.
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid rowprojects subtitol">
            <h2
              className="subtitol"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <mark className="mark1">Usability Testing:</mark>
              Preference Testing
            </h2>
            <div className="rowprojects row justify-content-center">
              <div className="col col-md-6 testing">
                <div>
                  <h5>Option 1</h5>
                  <img
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    src={Abtesting2}
                    className="img-fluid mapping Abtesting"
                    alt="Mapping UnregelApp"
                  ></img>
                </div>
              </div>
              <div className="col col-md-6 testing">
                <div>
                  <h5>Option 2</h5>
                  <img
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    src={Abtesting1}
                    className="img-fluid mapping Abtesting"
                    alt="Mapping UnregelApp"
                  ></img>
                </div>
              </div>
            </div>
            <div
              className="col-md-5 AboutProject"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <h6>Preference Testing</h6>
              <p>
                Between April 3 - 4 2019 we conducted a preference testing with
                17 participants. The tested screen is the Experty App Home, as
                we consider this page the most fundamental to transmit the right
                amount of information about our project. The testers hat two
                options, and the fundamental difference was found in the Search
                Bar location.
              </p>
              <h6>Preference Testing Results</h6>
              <p>
                We observed that the first screen (Option 1) was performing
                better (65% of the testers decided for this option) "and the
                difference was 90.0% likely to be statistically significant".
                With this information we could be fairly confident that this
                screen was in fact performing better, and not as result of
                random chance”. Given this argument obtained from our Preference
                Testing, we confidently decided to maintain the Search Bar in
                the centre of the Screen.
              </p>
            </div>
          </div>
          <div className="container-fluid rowprojects">
            <h2
              className="subtitol"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <mark className="mark1">Visual Design:</mark>
              the Gestalt Laws & the Principles of Design
            </h2>
            <div className="container-fluid gestalt">
              <div className="row">
                <div className="col-sm"></div>
                <div className="col-sm gestaltgif">
                  <img
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-bottom"
                    src={Gif}
                    className="img-fluid gif"
                    alt="Gif ExpertyApp"
                  ></img>
                </div>
                <div className="col-sm"></div>
              </div>
            </div>
            <div
              className="col-md-5 AboutProject AboutProject2 rowprojects"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <h6>Gestalt Psychology</h6>
              <p>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="careerfoundry"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-bottom"
                  href="https://www.youtube.com/watch?v=dk7cXdjX2Ys"
                >
                  Gestalt psychology
                </a>
                is a school of thought focused on understanding the way our
                minds organize and process the world around us. The movement was
                found in Germany around 1912 and has since been instrumental in
                explaining how our minds´ process information. The Six Gestalt
                Laws of Grouping are: Law of Proximity, Law of Similarity, Law
                of Closure, Law of Good Continuation, Law of Common Fate, Law of
                Good Form
              </p>
              <h6>Principles of Design</h6>
              <p>Unity, Balance, Hierarchy, Proportion and Emphasis</p>
              <h6>Principles applyied to our project</h6>
              <p>
                Principle of Hierarchy in the first screen: styling elements in
                such a way that they guide users through the screen. Low of
                Similarity in the onboarding: our mind is grouping visually
                similar elements. Low of Proximity in Log In and Sign Up
                screens: our minds automatically group together nearby elements.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm projects">
          <div
            className="col-sm-6"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <h1 className="title project1">
              Project #3
              <mark className="mark1">Wordpress Theme: "Science for All"</mark>
            </h1>
          </div>
        </div>
        <div className="container-fluid projects">
          <div
            className="rowprojects"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div className="row justify-content-end">
              <div className="col-lg-10">
                <img
                  src={WordpressTheme}
                  className="img-fluid ImageProject"
                  alt="UnregelApp"
                ></img>
              </div>
            </div>
            <div
              className="col-md-5 AboutProject"
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
            >
              <h6 data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                About Núria Coll-Bonfill's "Science for All" Theme
              </h6>
              <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                Núria Coll-Bonfill is a scientist based in the USA currently
                researching about the rare premature aging disease called
                Progeria. The theme "Science for All" portraits her biography,
                Curriculum Vitae, Media Publications and also a touch about her
                Personal life and interests.
              </p>
              <h6 data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                Web Technologies
              </h6>
              <p data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                Wordpress CMS, CSS, PHP.
              </p>
              <h6 data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                Wordpress Theme "Science for All"
              </h6>
              <a
                rel="noopener noreferrer"
                target="_blank"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                className="anchorInvision"
                href="https://nuriacollbonfill.com"
              >
                nuriacollbonfill.com
              </a>
            </div>
          </div>
        </div>
        <Music />
        <Footer />
      </div>
    );
  }
}

export default Projects;

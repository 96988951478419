import React, { Component } from "react";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Navbar extends Component {
  render(props) {
    return (
      <nav className="navbar navbar-light">
        <a className="navbar-brand" href="https://lidiapeleja.com">
          <p className="name">Lídia Pelejà</p>
          <p className="portfolio">Portfolio</p>
        </a>
        <ul className="nav navbar-nav ml-auto flex-row" id="linkedin">
          <li className="nav-item">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link linkedinbutton mx-1 mx-sm-3"
              href="https://sassmix.lidiapeleja.com/"
            >
              {" "}
              Tools<span className="spanblack"></span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link linkedinbutton"
              href="https://www.linkedin.com/in/lidiapeleja"
            >
              {" "}
              LinkedIn <span className="spanblack"></span>
            </a>
          </li>
        </ul>
        <span className="navbar-text">
          <div className="nav-link buttonmoon" href="#">
            <NightmodeToggleButton
              id="nightbutton"
              onClickHandler={this.props.toggleNightMode}
              nightmode={this.props.nightmode}
            ></NightmodeToggleButton>
          </div>
        </span>
      </nav>
    );
  }
}

const NightmodeToggleButton = (props) => {
  return (
    <button onClick={props.onClickHandler} className="buttonmoon">
      {props.nightmode ? (
        <FontAwesomeIcon icon="sun" className="sun" />
      ) : (
        <FontAwesomeIcon icon="moon" className="moon" />
      )}
    </button>
  );
};

export default Navbar;

import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Music.css";

class Music extends Component {
  render() {
    return (
      <div className="more container-fluid">
        <div className="row">
          <div className="col-md colleft">
            <div className="row">
              <h3>While getting inspired...</h3>
            </div>
          </div>
          <div className="col-md colright">
            <div className="row">
              <h3>
                ...by the 25 songs you shoud know
                <br></br>
                before you die.
              </h3>
            </div>
            <div className="row">
              <h5>Really.</h5>
            </div>
            <div className="row">
              <a
                target="_blank"
                rel="noopener noreferrer"
                role="button"
                href="https://open.spotify.com/playlist/036DQchf91JxFlEdyAWQW4?si=kurt77DmRbamK4jc4bRxmw"
                className="spotify"
              >
                <div className="textplay">
                  Play
                  <FontAwesomeIcon icon="play" className="play" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Music;

import React, {Component} from 'react';
import "./Jumbotron.css"

class Jumbotron2 extends Component {
  render() {
    return (<div className="jumbotron jumbotron2">
      <div className="container">
        <h1 className="display-4">“Have no fear of perfection<br></br>—you ’ll
          <mark2 className="mark2"> never </mark2>
          reach it.”</h1>
        <p className="lead">—Salvador Dalí</p>
      </div>
    </div>
          );
  }
}
export default Jumbotron2;

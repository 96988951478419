import React, {Component} from 'react';
import "./Jumbotron.css"

class Jumbotron1 extends Component {
  render() {
    return (<div className="container-fluid jumbo">
      <div className="jumbotron1">
        <h1 className="display-4">Hi, I am Lídia. <br></br> A <span className="markJobs">front-end Developer</span> &  <span className="markJobs">UX Designer</span> <br></br>based in <span className="markBarcelona"> Barcelona</span> Munich <br></br> who loves designing & building <br></br> <span role="img" aria-label="baloon">🎈</span> cool things<span role="img" aria-label="baloon"> 🎈</span>.</h1>
      </div>
    </div>);
  }
}
export default Jumbotron1;

import React, {Component} from 'react';
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";
import Jumbotron2 from "./Jumbotron2.js"
import "./Contactme.css";

class Form extends Component {
  constructor(props) {
    super(props);

    this.setName = this.setName.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.contactFormSubmitted = this.contactFormSubmitted.bind(this);

    this.state = {
      name: "",
      isNameValid: false,
      email: "",
      isEmailValid: false,
      message: "",
      isMessageValid: false,
      isFormSubmitted: false
    };
  }

  setName(event) {
    const name = event.target.value;

    this.setState({
      name: name,
      isNameValid: name !== ""
    });

    console.log("this is the peoples name -- typing on the form", event.target.value);
  };

  setEmail(event) {
    const email = event.target.value;

    this.setState({
      email: email,
      isEmailValid: email !== ""
    });

    console.log("this is the peoples email -- typing on the form", event.target.value);
  }

  setMessage(event) {
    const message = event.target.value;

    this.setState({
      message: message,
      isMessageValid: message !== ""
    });

    console.log("this is the message", event.target.value);
  }

  contactFormSubmitted(event) {
    event.preventDefault();
    this.setState({isFormSubmitted: true});

    console.log("form submitted");
  }

  render() {
    const isFormValid = this.state.isNameValid && this.state.isEmailValid && this.state.isMessageValid
    console.log('isFormValid', isFormValid);

    let textButton;

    if (isFormValid && this.state.isFormSubmitted) {
      textButton = "Message Sent"
    } else {
      textButton = "Send Message"
    }

    return (<div><Navbar/>
      <Jumbotron2/>
      <div className="containerform">
        <form onSubmit={this.contactFormSubmitted}>
          <div className="form-group container">
            <h1>Contact me here</h1>
            <label for="formGroupExampleInput">What is your name?</label>
            <input onChange={this.setName} value={this.state.name} type="text" className="form-control form-control-lg" id="formGroupExampleInput" placeholder="Lídia Pelejà"></input>
          </div>
          <div className="form-group container">
            <label for="formGroupExampleInput">What is your Email address?</label>
            <input onChange={this.setEmail} value={this.state.email} type="email" className="form-control form-control-lg" id="formGroupExampleInput" placeholder="name@example.com"></input>
          </div>
          <div className="form-group container">
            <label for="exampleFormControlTextarea1">Message</label>
            <textarea onChange={this.setMessage} value={this.state.message} className="form-control form-control-lg" id="exampleFormControlTextarea1" rows="4"></textarea>

            <button type="submit" class="btn" disabled={!isFormValid}>{textButton}</button>
          </div>
        </form>
      </div>
      <Footer/>
    </div>);
  }
}

export default Form;

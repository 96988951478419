import React, {Component} from 'react';
import "./Footer.css"

class Footer extends Component {
  render() {
    return (<div className="container-fluid projects">
      <div className="footer">
        <p>Coded with <span className="codedwithlove">love</span> & ReactJS by Lídia Pelejà</p>
        <p className="allrights">All rights reserved · {(new Date().getFullYear())}</p>
      </div>
    </div>);
  }
}
export default Footer;
